import React from 'react'
import { Layout } from 'antd';
import { useAppSelector } from "redux/store";
import Footer from "features/common/footer/Footer";
import UserPopoverMenu from "features/common/userPopoverMenu/UserPopoverMenu";
import YgagLogo from "images/YgagLogo";

import "./style.less";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

interface LayoutProps {
  heading?: string;
  children: React.ReactElement;
  alignCenter?: boolean;
}

const PublicLayout: React.FC<LayoutProps> = ({
  heading,
  children,
  alignCenter,
}) => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const splittedPath = pathname.split("/");
  const isErrorPage = splittedPath.includes("error");
  return (
    <Layout className="public-layout">
      <Layout.Header>
        {heading ? (
          <h1>{heading}</h1>
        ) : (
          <div className="public-layout--logo-container">
            <YgagLogo />
          </div>
        )}
        {isLoggedIn && !isErrorPage && (
          <div className="public-layout--user-menu">
            <UserPopoverMenu />
          </div>
        )}
      </Layout.Header>
      <Content className={alignCenter ? "align-center" : ""}>
        {children}
      </Content>
      <Footer />
    </Layout>
  );
};

export default PublicLayout;