const QA_OVERIDE_API_BASE_URL = "https://mycredits-console-qa.sit.yougotagift.co";

export const API_BASE_URL = `${
  process.env.REACT_APP_ENVIRONMENT === `qa`
    ? QA_OVERIDE_API_BASE_URL
    : process.env.REACT_APP_MYCREDITS_CONSOLE_API_BASE_URL
}/corporates/api/v1/`;

const API_URLS = {
    OAUTH_TOKEN: 'oauth/token/',
    HELPLINE: 'help-line-data/',
    LOGIN: 'login/',
    LOG_OUT: 'logout/',
    REFRESH_TOKEN: 'token/refresh/',
    PERMISSIONS: 'staff-permission/',
    RESET_PASSWORD_REQUEST: 'reset-password/',
    RESET_PASSWORD_SUBMIT: 'reset-password/confirm',
    CHANGE_PASSWORD:'change-password/',
    CORPORATE_LIST: 'corporates/',
    DASHBOARD: 'dashboard-view/',
    SELECT_CORPORATE: 'set-account/',
    USER_PROFILE: 'profile/',
    PROMOTIONS: 'promotions/',
    EXCEL_UPLOAD: 'upload/',
    DOWNLOAD_LATEST_FILE: 'latest-sps/download/',
    DOWNLOAD_SAMPLE_FILE: 'sample-sps/download/',
    FULFILMENTS: 'orders/',
    SAVED_ORDERS: 'saved-orders/',
    USERS: 'credit-users/',
    EXPORT_USERS: 'credit-users/export/',
    BLOCK_USERS: 'credit-users/block/',
    UNBLOCK_USERS: 'credit-users/unblock/',
    EXPIRY_REMINDER: 'credit/reminders/',
    CREDITS_IN_PROMOTION: (id: number) => (`promotions/${id}/credits/`)
}

export default API_URLS;
