import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "../features/common/authSlice";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

// Create the state sync middleware
const stateSyncMiddleware = createStateSyncMiddleware({
  whitelist: [setIsLoggedIn.type], // Specify the action types you want to sync across tabs
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, stateSyncMiddleware],
});

// Initialize the message listener for syncing states across tabs
initMessageListener(store);

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
